<template>
  <router-link v-slot="{ href, isActive, navigate }" :to="to" custom>
    <a
      :href="href"
      class="text-slate-700 dark:text-slate-100 font-medium w-10 h-10 flex my-1 items-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative"
      :class="{
        'bg-woot-50 dark:bg-slate-800 text-woot-500 hover:bg-woot-50': isActive,
        'w-full px-2': isSidebarOpen,
        'justify-center': !isSidebarOpen,
      }"
      :rel="undefined"
      :target="undefined"
      @click="navigate"
    >
      <fluent-icon
        icon="settings"
        :class="{
          'text-woot-500': isActive,
          'min-w-[1rem] mr-1.5': isSidebarOpen,
        }"
      />
      {{ isSidebarOpen ? $t('SIDEBAR.SETTINGS') : '' }}
    </a>
  </router-link>
</template>
<script>
import { frontendURL } from '../../../helper/URLHelper';

export default {
  props: {
    accountId: {
      type: Number,
      default: 0,
    },
    isSidebarOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    to() {
      return frontendURL(`accounts/${this.accountId}/settings`);
    },
  },
};
</script>
