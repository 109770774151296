<template>
  <div
    class="w-[50px] max-h-screen flex flex-col bg-slate-25 dark:bg-slate-850 text-sm justify-between"
    :class="{ 'min-w-[200px] max-w-[200px]': isSidebarOpen }"
  >
    <div class="flex flex-col justify-between h-full relative">
      <div class="w-full">
        <logo
          :is-sidebar-open="isSidebarOpen"
          :source="logoSource"
          :name="installationName"
          :account-id="accountId"
          class="mt-3 mb-4 w-full"
        />
        <div class="flex">
          <primary
            :is-sidebar-open="isSidebarOpen"
            :account-id="accountId"
            :menu-items="menuItems"
            :active-menu-item="activeMenuItem"
            @toggle-accounts="toggleAccountModal"
            @key-shortcut-modal="toggleKeyShortcutModal"
            @open-notification-panel="openNotificationPanel"
          />
          <div
            class="flex justify-end top-1/2 -translate-y-1/2 absolute right-0 w-fit"
          >
            <woot-button
              v-tooltip.left="$t('CONVERSATION.SWITCH_VIEW_LAYOUT')"
              icon="arrow-right-import"
              size="xtiny"
              variant="smooth"
              color-scheme="secondary"
              class="absolute layout-switch__container rounded-none rounded-l-full !w-fit !px-[0.3px] !h-12 !bg-slate-100 dark:!bg-slate-900"
              :class="{ expanded: isSidebarOpen }"
              @click="toggle"
            />
          </div>
        </div>
      </div>
      <div
        v-if="hasSecondaryMenu"
        class="flex justify-center h-full overflow-y-auto scrollbar-alt max-w-full"
      >
        <transition-group
          name="menu-list"
          tag="ul"
          class="p-3 flex flex-col list-none w-full"
          :class="{ 'items-center': !isSidebarOpen }"
        >
          <secondary-nav-item
            v-for="menuItem in accessibleMenuItems"
            :key="menuItem.toState"
            :is-sidebar-open="isSidebarOpen"
            :menu-item="menuItem"
            @toggle-sidebar="toggle"
          />
          <secondary-nav-item
            v-for="menuItem in additionalSecondaryMenuItems[
              menuConfig.parentNav
            ]"
            :key="menuItem.key"
            :is-sidebar-open="isSidebarOpen"
            :menu-item="menuItem"
            @add-label="showAddLabelPopup"
            @toggle-sidebar="toggle"
          />
        </transition-group>
      </div>
      <div
        class="flex flex-col h-fit border-t dark:border-slate-900 border-slate-50"
        :class="{ 'justify-center': !isSidebarOpen }"
      >
        <div
          class="flex flex-col p-3"
          :class="{ 'items-center': !isSidebarOpen }"
        >
          <account-context
            :is-sidebar-open="isSidebarOpen"
            @toggle-accounts="toggleAccountModal"
          />
          <notification-bell
            :is-sidebar-open="isSidebarOpen"
            @open-notification-panel="openNotificationPanel"
          />
          <div class="relative">
            <notification-panel
              v-if="isNotificationPanel"
              @close="closeNotificationPanel"
            />
            <config-button
              v-if="isSidebarOpen"
              :account-id="accountId"
              :is-sidebar-open="isSidebarOpen"
              :class="{ 'flex justify-center': !isSidebarOpen }"
            />
            <config-button
              v-else
              v-tooltip.right="$t(`SIDEBAR.SETTINGS`)"
              :account-id="accountId"
              :is-sidebar-open="isSidebarOpen"
              :class="{ 'flex justify-center': !isSidebarOpen }"
            />
            <options-menu
              :show="showOptionsMenu"
              @toggle-accounts="toggleAccountModal"
              @show-support-chat-window="toggleSupportChatWindow"
              @key-shortcut-modal="$emit('key-shortcut-modal')"
              @close="toggleOptions"
            />
            <agent-details
              v-if="isSidebarOpen"
              :is-sidebar-open="isSidebarOpen"
              class="my-2"
              :class="{ 'flex justify-center': !isSidebarOpen }"
              @toggle-menu="toggleOptions"
            />
            <agent-details
              v-else
              v-tooltip.right="$t(`SIDEBAR.PROFILE_SETTINGS`)"
              :is-sidebar-open="isSidebarOpen"
              class="my-2"
              :class="{ 'flex justify-center': !isSidebarOpen }"
              @toggle-menu="toggleOptions"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { frontendURL } from '../../../helper/URLHelper';
import SecondaryNavItem from './SecondaryNavItem.vue';
import { mapGetters, mapActions } from 'vuex';
import { FEATURE_FLAGS } from '../../../featureFlags';
import { hasPermissions } from '../../../helper/permissionsHelper';
import { routesWithPermissions } from '../../../routes';
import Logo from './Logo.vue';
import Primary from './Primary.vue';
import OptionsMenu from './OptionsMenu.vue';
import AgentDetails from './AgentDetails.vue';
import NotificationBell from './NotificationBell.vue';
import AccountContext from './AccountContext.vue';
import { ACCOUNT_EVENTS } from '../../../helper/AnalyticsHelper/events';
import NotificationPanel from '../../../routes/dashboard/notifications/components/NotificationPanel.vue';
import ConfigButton from './ConfigButton.vue';

import sidebar from '../../../store/modules/sidebar/sidebar';

export default {
  components: {
    ConfigButton,
    NotificationPanel,
    Primary,
    Logo,
    SecondaryNavItem,
    OptionsMenu,
    AgentDetails,
    NotificationBell,
    AccountContext,
  },
  props: {
    installationName: {
      type: String,
      default: '',
    },
    logoSource: {
      type: String,
      default: '',
    },
    activeMenuItem: {
      type: String,
      default: '',
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    accountId: {
      type: Number,
      default: 0,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    inboxes: {
      type: Array,
      default: () => [],
    },
    teams: {
      type: Array,
      default: () => [],
    },
    customViews: {
      type: Array,
      default: () => [],
    },
    menuConfig: {
      type: Object,
      default: () => {},
    },
    currentUser: {
      type: Object,
      default: () => {},
    },
    isOnChatwootCloud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSidebarOpen: true,
      showOptionsMenu: false,
      isNotificationPanel: false,
    };
  },
  computed: {
    ...mapGetters({
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      getIsSidebarOpen: 'sidebar/getIsSidebarOpen',
    }),
    hasSecondaryMenu() {
      return this.menuConfig.menuItems && this.menuConfig.menuItems.length;
    },
    contactCustomViews() {
      return this.customViews.filter(view => view.filter_type === 'contact');
    },
    accessibleMenuItems() {
      const menuItemsFilteredByPermissions = this.menuConfig.menuItems.filter(
        menuItem => {
          const { permissions: userPermissions = [] } = this.currentUser;
          return hasPermissions(
            routesWithPermissions[menuItem.toStateName],
            userPermissions
          );
        }
      );
      return menuItemsFilteredByPermissions.filter(item => {
        if (item.showOnlyOnCloud) {
          return this.isOnChatwootCloud;
        }
        return true;
      });
    },
    inboxSection() {
      return {
        icon: 'inboxes',
        label: 'INBOXES',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.INBOX_MANAGEMENT),
        newLinkTag: 'NEW_INBOX',
        key: 'inbox',
        toState: frontendURL(`accounts/${this.accountId}/settings/inboxes/new`),
        toStateName: 'settings_inbox_new',
        newLinkRouteName: 'settings_inbox_new',
        children: this.inboxes
          .map(inbox => ({
            id: inbox.id,
            label: inbox.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/inbox/${inbox.id}`
            ),
            type: inbox.channel_type,
            phoneNumber: inbox.phone_number,
            reauthorizationRequired: inbox.reauthorization_required,
          }))
          .sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
          ),
      };
    },
    labelSection() {
      return {
        icon: 'tags',
        label: 'LABELS',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_LABEL',
        key: 'label',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        dataTestid: 'sidebar-new-label-button',
        children: this.labels.map(label => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/label/${label.title}`
          ),
        })),
      };
    },
    contactLabelSection() {
      return {
        icon: 'number-symbol',
        label: 'TAGGED_WITH',
        hasSubMenu: true,
        key: 'label',
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_LABEL',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        children: this.labels.map(label => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/labels/${label.title}/contacts`
          ),
        })),
      };
    },
    teamSection() {
      return {
        icon: 'people-team',
        label: 'TEAMS',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_TEAM',
        key: 'team',
        toState: frontendURL(`accounts/${this.accountId}/settings/teams/new`),
        toStateName: 'settings_teams_new',
        newLinkRouteName: 'settings_teams_new',
        children: this.teams.map(team => ({
          id: team.id,
          label: team.name,
          truncateLabel: true,
          toState: frontendURL(`accounts/${this.accountId}/team/${team.id}`),
        })),
      };
    },
    foldersSection() {
      return {
        icon: 'filter',
        label: 'SAVED_FILTERS',
        hasSubMenu: true,
        key: 'custom_view',
        children: this.customViews
          .filter(view => view.filter_type === 'conversation')
          .map(view => ({
            id: view.id,
            label: view.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/custom_view/${view.id}`
            ),
          })),
      };
    },
    contactSegmentsSection() {
      return {
        icon: 'folder',
        label: 'CUSTOM_VIEWS_SEGMENTS',
        hasSubMenu: true,
        key: 'custom_view',
        children: this.customViews
          .filter(view => view.filter_type === 'contact')
          .map(view => ({
            id: view.id,
            label: view.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/contacts/custom_view/${view.id}`
            ),
          })),
      };
    },
    additionalSecondaryMenuItems() {
      let conversationMenuItems = [this.inboxSection, this.labelSection];
      let contactMenuItems = [this.contactLabelSection];
      if (this.teams.length) {
        conversationMenuItems = [this.teamSection, ...conversationMenuItems];
      }
      if (this.customViews.length) {
        conversationMenuItems = [this.foldersSection, ...conversationMenuItems];
      }
      if (this.contactCustomViews.length) {
        contactMenuItems = [this.contactSegmentsSection, ...contactMenuItems];
      }
      return {
        conversations: conversationMenuItems,
        contacts: contactMenuItems,
      };
    },
  },
  methods: {
    ...mapActions('sidebar', ['updateSidebarOpenState']),

    toggle() {
      this.isSidebarOpen = !this.isSidebarOpen;
      this.updateSidebarOpenState(this.isSidebarOpen);
    },
    showAddLabelPopup() {
      this.$emit('add-label');
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    showNewLink(featureFlag) {
      return this.isFeatureEnabledonAccount(this.accountId, featureFlag);
    },
    toggleKeyShortcutModal() {
      this.showShortcutModal = true;
    },
    openNotificationPanel() {
      this.$track(ACCOUNT_EVENTS.OPENED_NOTIFICATIONS);
      this.isNotificationPanel = true;
    },
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    closeNotificationPanel() {
      this.isNotificationPanel = false;
    },
  },
};
</script>

<style lang="scss">
.scrollbar-alt {
  &::-webkit-scrollbar {
    height: 33px;
    width: 33px;
    -webkit-border-radius: 1ex;
  }
  &::-webkit-scrollbar-thumb {
    @apply bg-slate-300 dark:bg-slate-300;
    border: 15px solid #00000000;
    background-clip: padding-box;
    -webkit-border-radius: 6ex;
  }
}
</style>
