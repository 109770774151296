import * as types from '../../mutation-types'

const state = {
  isSidebarOpen: true,
}

export const getters = {
  getIsSidebarOpen($state) {
    return $state.isSidebarOpen
  }
}

export const actions = {
  updateSidebarOpenState({commit}, isOpen) {
    commit(types.default.TOGGLE_SIDEBAR, isOpen)
  }
}

export const mutations = {
  [types.default.TOGGLE_SIDEBAR]($state, isOpen) {
    $state.isSidebarOpen = isOpen
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
