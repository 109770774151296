var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isSidebarOpen)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.$t('NOTIFICATIONS_PAGE.HEADER')),expression:"$t('NOTIFICATIONS_PAGE.HEADER')",modifiers:{"right":true}}],staticClass:"text-slate-600 dark:text-slate-100 w-10 h-10 my-1 p-0 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative",class:{
      'bg-woot-50 dark:bg-slate-800 text-woot-500 hover:bg-woot-50':
        _vm.isNotificationPanelActive,
    },on:{"click":_vm.openNotificationPanel}},[_c('fluent-icon',{class:{
        'text-woot-500': _vm.isNotificationPanelActive,
      },attrs:{"icon":"alert"}}),_vm._v(" "),(_vm.unreadCount)?_c('span',{staticClass:"text-black-900 bg-yellow-300 absolute -top-0.5 -right-1 text-xxs min-w-[1rem] rounded-full"},[_vm._v("\n      "+_vm._s(_vm.unreadCount)+"\n    ")]):_vm._e()],1):_c('button',{staticClass:"flex items-center p-2 m-0 font-medium leading-4 rounded-lg my-1 text-slate-700 dark:text-slate-100 hover:bg-slate-25 dark:hover:bg-slate-700 w-full",on:{"click":_vm.openNotificationPanel}},[_c('div',{staticClass:"relative"},[_c('fluent-icon',{staticClass:"min-w-[1rem] mr-1.5",class:{
          'text-woot-500': _vm.isNotificationPanelActive,
        },attrs:{"icon":"alert"}}),_vm._v(" "),(_vm.unreadCount)?_c('span',{staticClass:"text-black-900 bg-yellow-300 absolute bottom-4 -right-1.5 text-xxs min-w-[1rem] rounded-full"},[_vm._v("\n        "+_vm._s(_vm.unreadCount)+"\n      ")]):_vm._e()],1),_vm._v("\n    "+_vm._s(_vm.$t('NOTIFICATIONS_PAGE.HEADER'))+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }