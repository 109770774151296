<template>
  <div class="flex items-center justify-center">
    <router-link :to="dashboardPath" replace class="w-full">
      <div v-if="isSidebarOpen" class="flex items-center w-full gap-2 ps-5">
        <img class="w-8 h-8" :src="source" :alt="name" />
        <div class="flex">
          <span class="text-xl font-bold text-itichatLightGreen m-0 w-fit">
            {{ name.split('Chat')[0] }}
          </span>
          <span
            class="text-xl font-bold text-slate-700 dark:text-slate-100 m-0 w-fit"
          >
            {{ name.split('iti')[1] }}
          </span>
          <div class="ml-1 items-end flex">
            <p
              class="flex justify-center rounded-lg bg-yellow-600 font-bold text-slate-700 text-xxxs w-fit h-fit m-0 py-0 px-1"
            >
              beta
            </p>
          </div>
        </div>
      </div>
      <div v-else class="flex items-center justify-center w-full mx-auto">
        <img class="w-8 h-8" :src="source" :alt="name" />
      </div>
    </router-link>
  </div>
</template>
<script>
import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  props: {
    isSidebarOpen: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    dashboardPath() {
      return frontendURL(`accounts/${this.accountId}/dashboard`);
    },
  },
};
</script>
