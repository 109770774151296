var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMenuItemVisible),expression:"isMenuItemVisible"}],staticClass:"mt-1 mx-1"},[(_vm.hasSubMenu && _vm.isSidebarOpen)?_c('hr',{staticClass:"my-2 border-slate-300 dark:border-slate-600"}):_vm._e(),_vm._v(" "),(_vm.hasSubMenu && _vm.isSidebarOpen)?_c('div',{staticClass:"flex justify-between"},[_c('span',{staticClass:"px-2 pt-1 my-2 text-xs font-bold text-slate-500 dark:text-slate-500"},[_vm._v("\n      "+_vm._s(_vm.$t(`SIDEBAR.${_vm.menuItem.label}`))+"\n    ")]),_vm._v(" "),(_vm.menuItem.showNewButton)?_c('div',{staticClass:"flex items-center"},[_c('woot-button',{staticClass:"p-0 ml-2",attrs:{"size":"tiny","variant":"clear","color-scheme":"secondary","icon":"add"},on:{"click":_vm.onClickOpen}})],1):_vm._e()]):(!_vm.hasSubMenu && _vm.isSidebarOpen)?_c('router-link',{staticClass:"flex gap-2 relative items-center p-2 m-0 text-xs font-medium leading-4 rounded-lg text-slate-700 dark:text-slate-100 hover:bg-slate-25 dark:hover:bg-slate-800",class:_vm.computedClass,attrs:{"to":_vm.menuItem && _vm.menuItem.toState}},[_c('div',{staticClass:"bg-woot-350 w-[2px] h-5 absolute top-1/2 -translate-y-1/2 left-0",class:_vm.computedClassAux}),_vm._v(" "),_c('div',{staticClass:"flex items-center"},[_c('fluent-icon',{staticClass:"min-w-[1rem]",attrs:{"icon":_vm.menuItem.icon,"size":"20"}})],1),_vm._v("\n    "+_vm._s(_vm.$t(`SIDEBAR.${_vm.menuItem.label}`))+"\n    "),(_vm.showChildCount(_vm.menuItem.count))?_c('span',{staticClass:"px-1 py-0 mx-1 font-medium rounded-md text-xxs",class:{
        'text-slate-300 dark:text-slate-600': _vm.isCountZero && !_vm.isActiveView,
        'text-slate-600 dark:text-slate-50': !_vm.isCountZero && !_vm.isActiveView,
        'bg-woot-75 dark:bg-woot-200 text-woot-600 dark:text-woot-600':
          _vm.isActiveView,
        'bg-slate-50 dark:bg-slate-700': !_vm.isActiveView,
      }},[_vm._v("\n      "+_vm._s(`${_vm.menuItem.count}`)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.menuItem.beta)?_c('span',{staticClass:"inline-block px-1 mx-1 font-medium leading-4 text-green-500 border border-green-400 rounded-lg text-xxs",attrs:{"data-view-component":"true","label":"Beta"}},[_vm._v("\n      "+_vm._s(_vm.$t('SIDEBAR.BETA'))+"\n    ")]):_vm._e()]):(_vm.hasSubMenu && !_vm.isSidebarOpen)?_c('div',{staticClass:"flex justify-between"},[_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t(`SIDEBAR.${_vm.menuItem.label}`),
        placement: 'right',
        offset: '100, -20',
      }),expression:"{\n        content: $t(`SIDEBAR.${menuItem.label}`),\n        placement: 'right',\n        offset: '100, -20',\n      }"}],staticClass:"button clear",attrs:{"icon":_vm.menuItem.icon,"size":"20","color-scheme":"secondary"},on:{"click":function($event){return _vm.$emit('toggle-sidebar')}}})],1):_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.$t(`SIDEBAR.${_vm.menuItem.label}`),
      placement: 'right',
      offset: '100, -20',
    }),expression:"{\n      content: $t(`SIDEBAR.${menuItem.label}`),\n      placement: 'right',\n      offset: '100, -20',\n    }"}],staticClass:"w-10 h-10 flex relative items-center justify-center p-2 m-0 text-xs font-medium leading-4 rounded-lg text-slate-700 dark:text-slate-100 hover:bg-slate-25 dark:hover:bg-slate-800",class:_vm.computedClass,attrs:{"to":_vm.menuItem && _vm.menuItem.toState}},[_c('div',{staticClass:"bg-woot-350 w-[2px] h-5 absolute top-1/2 -translate-y-1/2 left-0",class:_vm.computedClassAux}),_vm._v(" "),_c('fluent-icon',{class:{
        'text-slate-300 dark:text-slate-600': _vm.isCountZero && !_vm.isActiveView,
        'text-slate-600 dark:text-slate-50': !_vm.isCountZero && !_vm.isActiveView,
        'text-slate-600 dark:text-woot-350': _vm.isActiveView,
      },attrs:{"icon":_vm.menuItem.icon,"size":"20"}}),_vm._v(" "),(_vm.showChildCount(_vm.menuItem.count))?_c('span',{staticClass:"px-1 py-0 mx-1 font-medium rounded-md text-xxs"},[_vm._v("\n      "+_vm._s(`${_vm.menuItem.count}`)+"\n    ")]):_vm._e()],1),_vm._v(" "),(_vm.isSidebarOpen)?_c('div',[(_vm.hasSubMenu)?_c('ul',{staticClass:"mb-0 ml-0 list-none"},[_vm._l((_vm.menuItem.children),function(child){return _c('secondary-child-nav-item',{key:child.id,attrs:{"to":child.toState,"label":child.label,"label-color":child.color,"should-truncate":child.truncateLabel,"icon":_vm.computedInboxClass(child),"warning-icon":_vm.computedInboxErrorClass(child),"show-child-count":_vm.showChildCount(child.count),"child-item-count":child.count}})}),_vm._v(" "),_c('Policy',{attrs:{"permissions":['administrator']}},[(_vm.menuItem.newLink)?_c('router-link',{attrs:{"to":_vm.menuItem.toState,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('li',{staticClass:"pl-1"},[_c('a',{attrs:{"href":href}},[_c('woot-button',{attrs:{"size":"tiny","variant":"clear","color-scheme":"secondary","icon":"add","data-testid":_vm.menuItem.dataTestid},on:{"click":e => _vm.newLinkClick(e, navigate)}},[_vm._v("\n                "+_vm._s(_vm.$t(`SIDEBAR.${_vm.menuItem.newLinkTag}`))+"\n              ")])],1)])]}}],null,false,3791782989)}):_vm._e()],1)],2):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }